
import {
  defineComponent,
  onBeforeMount,
  useContext,
  useMeta,
  useRoute,
  watch,
} from '@nuxtjs/composition-api';
import { SfSkeleton } from '@storefront-ui/vue';
import { onSSR, sharedRef } from '@vue-storefront/core';
import { EditorContextHelper } from '@magnolia/template-annotations';

import { Logger } from '~/apiExtensions/utils';

import config from '~/magnolia/magnolia.config';
import StageTeaserSkeleton from '~/components/Skeletons/StageTeaserSkeleton.vue';
import { EditablePage } from './ContentEditor.vue';
import { useAppLocale } from '~/composables/custom/useAppLocale';
import { usePageLoader } from '~/composables/custom/usePageLoader/usePageLoader';
import { useMagnolia } from '~/composables/custom/useMagnolia/useMagnolia';
import { useHrefLang } from '~/composables/useHrefLang';

function removeCurrentLanguage(path: string, currentLanguage: string) {
  return path.replace(new RegExp(`/${currentLanguage}($|/)`), '/');
}

function getLanguages(mgnlAppLanguages: string) {
  return mgnlAppLanguages.split(' ');
}

function getCurrentLanguage(path: string, mgnlAppLanguages: string) {
  const languages = getLanguages(mgnlAppLanguages);

  if (!languages.length) {
    throw new Error('No language provided. Please make sure to add locales.');
  }

  return (
    languages.find((language) => new RegExp(`/${language}($|/i)`).test(path)) || languages[0]
  );
}

const getPath = (path: string) => (path.startsWith('/') ? path.substr(1) : path);

export default defineComponent({
  name: 'PageLoader',
  comments: true,
  components: { EditablePage, StageTeaserSkeleton, SfSkeleton },
  setup() {
    const {
      $config, $domain, error, redirect,
    } = useContext();
    const { link, meta } = useMeta();
    const route = useRoute();
    const { country } = useAppLocale();
    const { atMagnolia, context } = useMagnolia();
    const { setHrefLangForMagnolia } = useHrefLang();
    const {
      fetchContent, loading, result: content, error: contentError,
    } = usePageLoader();

    link.value.push({
      rel: 'canonical',
      href: `${$domain.host}${route.value.path}`,
    });

    const mgnlBaseSiteName = `/${country}`;

    const {
      mgnlAppLanguages,
      mgnlRestTemplateAnnotations,
      mgnlRestPages,
    } = $config;

    const getContentUrl = (mgnlpath: string) => {
      const languages = getLanguages(mgnlAppLanguages);
      const currentLanguage = getCurrentLanguage(mgnlpath, mgnlAppLanguages);
      let path = mgnlBaseSiteName + mgnlpath.replace(new RegExp(`(.*${mgnlBaseSiteName})`, 'g'), '');

      if (currentLanguage !== languages[0]) {
        path = removeCurrentLanguage(path, currentLanguage);
        path += `?lang=${currentLanguage}`;
      }

      const version = route.value.query?.mgnlVersion;
      if (version) {
        path += path.includes('?') ? `&version=${version}` : `?version=${version}`;
      }

      if (route.value.query.mgnlPreviewAsVisitor !== 'true' && EditorContextHelper.inIframe()) {
        path += path.includes('?') ? '&variants=all' : '?variants=all';
      }

      return `${mgnlRestPages}${path}`;
    };

    const templateDefinitions = sharedRef(undefined, `templateDefinitions-${route.value.path}`);

    const basePath = `${mgnlBaseSiteName}/Home`;

    const pathMatch = () => {
      if (route.value.path.includes(basePath)) {
        return route.value.path;
      }

      if (route.value.path === '' || route.value.path === '/') {
        return basePath;
      }

      return `${basePath}/${getPath(route.value.path)}`;
    };

    const getTemplateAnnotations = async () => {
      try {
        const match = new RegExp(`(.*${mgnlBaseSiteName}|.html)`, 'g');

        const path: string = mgnlBaseSiteName + pathMatch().replace(match, '');
        return fetchContent(mgnlRestTemplateAnnotations + path);
      } catch (e) {
        Logger.error(`[${e.name}] Getting template annotations from magnolia is taking too long`);
      }
      return undefined;
    };

    const loadPage = async () => {
      try {
        if (route.value.path.endsWith('.html')) {
          redirect(301, route.value.path.replace(/\.html$/, ''));
        }

        const url = getContentUrl(pathMatch());

        await fetchContent(url);

        if (contentError.value.content || contentError.value.contentStatusCode === 404) {
          error({ statusCode: 404 });
        }
      } catch (e) {
        const err: any = {
          route: route.value.path,
          contentUrL: getContentUrl(pathMatch()),
          error: e,
        };
        Logger.error('Error Fetching content: ', err);
      }
    };

    const setMeta = () => {
      meta.value = content.value?.meta?.['@nodes'].map((node) => (
        {
          name: content.value.meta[node].name,
          content: content.value.meta[node].content,
        }
      )) ?? [];
    };

    onSSR(async () => {
      if (!atMagnolia.value) {
        await loadPage();
      }
    });

    onBeforeMount(async () => {
      if (atMagnolia.value) {
        templateDefinitions.value = await getTemplateAnnotations();
        await loadPage();
      }
      setMeta();
      setHrefLangForMagnolia(content.value?.hrefLang);
    });

    watch(content, (value) => {
      if (value) {
        setMeta();
        setHrefLangForMagnolia(value?.hrefLang);
      }
    });

    return {
      loading,
      content,
      context,
      templateDefinitions,
      config,
    };
  },
  head() {
    const campaignName = this.content?.title ?? this.content?.['@name'];

    return {
      title: `${campaignName} | KiK Onlineshop`,
      meta: [
        {
          name: 'apple-mobile-web-app-title',
          hid: 'apple-mobile-web-app-title',
          property: 'apple-mobile-web-app-title',
          content: `${campaignName} | KiK Onlineshop`,
        },
        {
          name: 'og:title',
          hid: 'og:title',
          property: 'og:title',
          content: `${campaignName} | KiK Onlineshop`,
        },
        {
          name: 'description',
          hid: 'description',
          property: 'description',
          content: `${campaignName} ✓ KiK Onlineshop`,
        },
        {
          name: 'og:description',
          hid: 'og:description',
          property: 'og:description',
          content: `${campaignName} ✓ ${this.$t('Great offers now on KiK')}`,
        },
      ],
    };
  },
});

import { useContext } from '@nuxtjs/composition-api';
import { MetaInfo } from 'vue-meta/types';

export const useHrefLang = () => {
  const {
    app, route, $config, i18n,
  } = useContext();

  const setHrefLangForProduct = (hrefLang) => {
    const links = $config.mainCountryCodes
      .filter(({ locales }) => hrefLang[locales[0]])
      .map(({ host, locales }) => ({
        rel: 'alternate',
        hid: `hreflang-${locales[0]}`,
        href: `${host}/p/${hrefLang[locales[0]]}/${route.value?.params.id}`,
        hreflang: locales[0],
      }));
    addLinks(links);

    return links;
  };

  const setHrefLangForCategory = (slug1: Record<string, string>, slug2: Record<string, string>, isSale: boolean, isNew: boolean) => {
    const getHref = (host, locale): string => {
      let mainCategory = 'c';
      if (isSale) {
        mainCategory = `c/s/${i18n.t('Sale').toString().toLowerCase()}`;
      }
      if (isNew) {
        mainCategory = `c/n/${i18n.t('New').toString().toLowerCase()}`;
      }
      if (!slug1) {
        return `${host}/${mainCategory}`;
      }
      if (slug2) {
        return `${host}/${mainCategory}/${slug1[locale]}/${slug2[locale]}`;
      }

      return `${host}/${mainCategory}/${slug1[locale]}`;
    };

    const links = $config.mainCountryCodes.map(({ host, locales }) => ({
      rel: 'alternate',
      hid: `hreflang-${locales[0]}`,
      href: getHref(host, locales[0]),
      hreflang: locales[0],
    }));
    addLinks(links);

    return links;
  };

  const setHrefLangForMagnolia = (hrefLang) => {
    if (!hrefLang) {
      return [];
    }
    const links = hrefLang['@nodes'].map((node) => {
      const [, country, ...rest] = hrefLang[node].page['@path'].replace('/Home', '').split('/');
      const countryCodeConfig = $config.mainCountryCodes.find(({ countryCode }) => countryCode === country);
      return {
        rel: 'alternate',
        hid: `hreflang-${countryCodeConfig.locales[0]}`,
        href: [countryCodeConfig.host, ...rest].filter(Boolean).join('/'),
        hreflang: countryCodeConfig.locales[0],
      };
    });
    addLinks(links);

    return links;
  };

  const addLinks = (link) => {
    const links = (app.head as () => MetaInfo)().link.filter(({ hid }) => !hid?.startsWith('hreflang')); // filter out the previous ones
    links.push(...link);
    (app.head as () => MetaInfo)().link = links;
  };

  return {
    setHrefLangForProduct,
    setHrefLangForCategory,
    setHrefLangForMagnolia,
  };
};

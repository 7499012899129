
import { defineComponent } from '@nuxtjs/composition-api';
import PageLoader from '~/components/Shared/Magnolia/PageLoader.vue';

export default defineComponent({
  name: 'ContentPage',
  components: {
    PageLoader,
  },
  layout: 'magnolia',
  middleware: 'cache-control',
});
